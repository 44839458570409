import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DetalleComponent } from './detalle/detalle.component';
import { ContactoComponent } from './contacto/contacto.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { DetalleNoticiasComponent } from './detalle-noticias/detalle-noticias.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { CompraComponent } from './compra/compra.component';
import { TerminosComponent } from './terminos/terminos.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: 'nosotros', component: NosotrosComponent},
  {path: 'noticias', component: NoticiasComponent},
  {path: 'compras', component: CompraComponent},
  {path: 'terminos', component: TerminosComponent},
  {path: 'noticia/:id', component: DetalleNoticiasComponent},
  {path: 'detalle/:id', component: DetalleComponent},
  { path: '**', component: HomeComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
