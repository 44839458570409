import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { HomeService } from '../services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [
    // { provide: CarouselConfig, useValue: { interval: 10000, noPause: true, showIndicators: true } }
  ]
})
export class HomeComponent implements OnInit {

  itemsPerSlide = 12;
  singleSlideOffset = true;
  p: number = 1; // Página actual
  total: number = 0;
  // noWrap = true;

  slides = [
    {image: 'assets/asociados/1.jpg'},
    {image: 'assets/asociados/2.jpg'},
    {image: 'assets/asociados/3.jpg'},
    {image: 'assets/asociados/4.jpg'},
    {image: 'assets/asociados/5.jpg'},
    {image: 'assets/asociados/6.jpg'},
    {image: 'assets/asociados/7.jpg'},
    {image: 'assets/asociados/8.jpg'},
    {image: 'assets/asociados/9.jpg'},
    {image: 'assets/asociados/10.jpg'},
    {image: 'assets/asociados/11.jpg'},
    {image: 'assets/asociados/12.jpg'},
    {image: 'assets/asociados/13.jpg'},
    {image: 'assets/asociados/14.jpg'},
    {image: 'assets/asociados/15.jpg'},
    {image: 'assets/asociados/16.jpg'},
    {image: 'assets/asociados/17.jpg'},
    {image: 'assets/asociados/18.jpg'},
    {image: 'assets/asociados/19.jpg'},
    {image: 'assets/asociados/20.jpg'}
  ];
  // modelos para filtros
  conce: any;
  kilo: any;
  prec: any;


  activos: any;
  maxSize: any;
  concesionarios: any;
  marcas: any;
  lineas: any;
  modelos: any;

  //modelos
  concesionario: any;
  marca:any;
  linea: any;
  modelo: any;
  kilometrajeselect: any;
  precioselect: any;

  //flags de filtros
  aplicaconcesionario = false;
  aplicamarca = false;
  aplicalinea = false;
  aplicamodelo = false;
  aplicaubicacion = false;
  aplicakilometraje = false;
  aplicaprecio = false;

  constructor(private _home: HomeService) {

  }

  ngOnInit() {

    this.listarActivos();
    this.cargarConcecionarios();
    this.cargarMarcas();
    this.cargarModelos();

  }

  listarActivos() {
    this._home.listarVehiculos().subscribe(
      (res: any) => {
        // console.log(res);
        this.activos = res.data;
        this.total = res.data.length;
      }
    );
  }

  cargarConcecionarios() {
    this._home.listarConcesionarios().subscribe(
      (response: any) => {
        this.concesionarios = response;
        console.log(this.concesionarios);

      }
    );
  }

  cargarMarcas() {
    this._home.listarMarcas().subscribe(
      (response: any) => {
        this.marcas = response;
      }
    );
  }

  cargarLineas() {

    this._home.listarLineas(this.marca).subscribe(
      (response: any) => {
        this.lineas = response;
      }
    );
  }

  cargarModelos() {

    this._home.listarModelos().subscribe(
      (response: any) => {
        this.modelos = response;
      }
    );
  }


  filtrar() {

    const data = {
      concesionario: this.concesionario,
      marca: this.marca,
      linea: this.linea,
      anio: this.modelo,
      // ubicacion: this.ubicacionelect,
      kilometraje: this.kilometrajeselect,
      precio: this.precioselect
    };

    this._home.filtrar(data).subscribe(
      (res: any) => {
        this.activos = res;
        this.total = res.length;
        console.log(res);

        if (this.concesionario === undefined || this.concesionario === '' || this.concesionario === null) {
          this.aplicaconcesionario = false;
        } else {
          this.conce = this.buscarConcesionarioActivo();

          this.aplicaconcesionario = true;
        }
        if (this.marca === undefined || this.marca === '' || this.marca === null) {
          this.aplicamarca = false;
        } else {
          this.aplicamarca = true;
        }
        if (this.linea === undefined || this.linea === '' || this.linea === null) {
          this.aplicalinea = false;
        } else {
          this.aplicalinea = true;
        }
        if (this.modelo === undefined || this.modelo === '' || this.modelo === null) {
          this.aplicamodelo = false;
        } else {
          this.aplicamodelo = true;
        }
        if (this.precioselect === undefined || this.precioselect === '' || this.precioselect === null ) {
          this.aplicaprecio = false;
        }else {
          switch (this.precioselect) {
            case 'uno':
                this.prec = '0-10mm';
                break;
              case 'dos':
                this.prec = '10-50mm';
                break;
              case 'tres':
                this.prec = '50-150mm';
                break;
              case 'cuatro':
                this.prec = 'mas de 150mm';
                break;
              default:
                break;
          }
          this.aplicaprecio = true;
        }
        if (this.kilometrajeselect === undefined || this.kilometrajeselect === '' || this.kilometrajeselect === null ) {
          this.aplicakilometraje = false;
        }else {
          switch (this.kilometrajeselect) {
            case '1':
                this.kilo = '0 - 25.000 km';
                break;
              case '2':
                this.kilo = '25.000 - 50.000 km';
                break;
              case '3':
                this.kilo = '50.000 - 100.000 km';
                break;
              case '4':
                this.kilo = 'Mas de 100.000 km';
                break;
              default:
                break;
          }
          this.aplicakilometraje = true;
        }
      }
    );

  }

  buscarConcesionarioActivo() {
    let r: any;
    r = this.concesionarios.find(e => e.id == this.concesionario);

    return r.nombre;
  }

  quitarconcesionario() {
    this.concesionario = null;
    this.aplicaconcesionario = false;
    this.filtrar();
  }

  quitarmarca() {
    this.marca = undefined;
    this.aplicamarca = false;
    this.filtrar();
  }

  quitarlinea() {
    this.linea = undefined;
    this.aplicalinea = false;
    this.filtrar();
  }

  quitarmodelo() {
    this.modelo = undefined;
    this.aplicamodelo = false;
    this.filtrar();
  }

  quitarkilometraje() {
    this.kilometrajeselect = null;
    this.aplicakilometraje = false;
    this.filtrar();
  }

  quitarprecio() {
    this.precioselect = null;
    this.aplicaprecio = false;
    // this.borraprecio = false;
    this.filtrar();
  }

  buscarConcesionario(id_concesionario) {
    let data = {
      concesionario: id_concesionario
    };

    this._home.filtrar(data).subscribe(
      (res:any) => {
        this.activos = res;
        this.aplicaconcesionario = true;
        let r: any;
        r = this.concesionarios.find(e => e.id == id_concesionario);
        this.conce = r.nombre;
        this.concesionario = r.id;

      }
    );

  }



}
