import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { CompraService } from '../services/compra.service';


@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.css']
})
export class CompraComponent implements OnInit {

  nombre: string;
  email: string;
  telefono: string;
  marcas: [] = [];
  referencias: [] = [];
  version: string;
  modelos: [] = [];
  transmision: string;
  cilindraje: string;
  kilometraje: string;
  precio: any;
  comentarios: string;
  registroForm!: FormGroup;

  constructor( private _compra: CompraService, private fb: FormBuilder ) { }

  ngOnInit() {
    this.registroForm = this.fb.group({
      nombre: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', [Validators.required]],
      marca: ['', [Validators.required]],
      referencia: ['', [Validators.required]],
      kilometraje: ['', [Validators.required]],
      modelo: ['', [Validators.required]]
    });
    this.listMarcas()
  }

  listMarcas(){
    this._compra.listMarcas().subscribe(
      (resp: any) => {

        this.marcas = resp.data;
      }
    );
  }

  listReferencias(){



    this._compra.listReferencias(this.registroForm.value.marca).subscribe(
      (resp: any) => {

        this.referencias = resp.data;
      }
    );
  }

  listModelos(){


    this._compra.listModelos(this.registroForm.value.marca, this.registroForm.value.referencia).subscribe(
      (resp: any) => {

        this.modelos = resp.data;
      }
    );
  }

  consultarPrecio(){
    this._compra.vehiculoEncotrado(this.registroForm.value.marca, this.registroForm.value.referencia,this.registroForm.value.modelo).subscribe(
      (resp: any) => {

        if (resp.data.length > 0) {
          this.precio = resp.data[0].Precio
          if (this.registroForm.valid == false) {
            Swal.fire('Opps!', 'Lo sentimos, debes llenar todos los campos!', 'error');
          }else{
            Swal.fire({
              title: `$${this.formatToMillions(this.precio)}`,
              text: "Este es el precio que tenemos para ti!",
              icon: "success",
              showCancelButton: true,
              confirmButtonColor: "#FF5000",
              cancelButtonColor: "#7d7d7d",
              confirmButtonText: "Quiero que me llamen!",
              cancelButtonText: "No, gracias"
            }).then((result) => {
              console.log(result);

              if (result.value) {
                this.guardarCompra();
              }
            });

          }
        }
      }
    );
  }

  guardarCompra() {
    console.log(this.registroForm);
    let data = {
      nombre: this.registroForm.value.nombre,
      email: this.registroForm.value.email,
      telefono: this.registroForm.value.telefono,
      marca: this.registroForm.value.marca,
      referencia: this.registroForm.value.referencia,
      modelo: this.registroForm.value.modelo,
      kilometraje: this.registroForm.value.kilometraje,
      precio: this.precio

    };
    this._compra.saveCompra(data).subscribe(
      (res: any) => {
        console.log(res);
        this.registroForm.reset();

      }
    );

  }
  formatToMillions(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

}
