import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  
  constructor( private _http:HttpClient ) { }

  p = 1;

  listarVehiculos() {
    return this._http.get(environment.url+`listaractivos`);
  }

  listarConcesionarios() {
    return this._http.get( environment.url + 'listarconcesionarios' );
  }
  listarMarcas() {
    return this._http.get( environment.url + 'listarmarcas' );
  }

  listarLineas(marca) {
    return this._http.get( environment.url + 'listarlineas/' + marca );
  }
  
  listarModelos() {
    return this._http.get( environment.url + 'listarmodelos' );
  }

  filtrar(data) {
    return this._http.post(environment.url + 'filtro', data);
  }


}
