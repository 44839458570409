import { Component, OnInit } from '@angular/core';
import { CompraService } from '../services/compra.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  email: string;
  nombre: string;
  comentario: string;
  terminos = true;

  constructor( private _compra: CompraService ) { }

  ngOnInit() {
  }

  saveSoporte(formulario) {
    
    let terminos = 0;
    if (formulario.value.terminos) {
      terminos = 1;
    }

      const data = {
        nombre: formulario.value.nombre,
        email: formulario.value.email,
        comentario: formulario.value.comentario,
        terminos: terminos,
        portal: 'okcar'
      };

      this._compra.saveLeadSoporte(data).subscribe(
        (res: any) => {
          Swal.fire('Genial!', 'Un asesor pronto se contactará contigo!', 'success');
          formulario.reset();
        },
        (error: any)=> {
          console.log(error.error);
          Swal.fire('Oops!', 'Los campos email, nombre y comentario son obligatorios!', 'error');
          
        } 
      );
      

    
    
  }

}
