import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import 'hammerjs';
import { DetalleService } from '../services/detalle.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages = [];

  id: any;
  carro: any;
  email: string;
  nombre: string;
  telefono: string;
  terminos: any = true;

  constructor(private _detalle: DetalleService, public route: ActivatedRoute,) {
    this.route.params.subscribe(params => {
        this.id = params.id;
      });
  }

  ngOnInit() {

    this.cargarVehiculo();

    this.galleryOptions = [
      {
          width: '100%',
          height: '375px',
          thumbnailsColumns: 4,
          thumbnailSize: 'cover',
          imageSize: 'cover',
          thumbnailsRemainingCount: true,
          imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
          breakpoint: 800,
          width: '100%',
          height: '600px',
          imageSwipe: true,
          thumbnailsSwipe: true,
          previewSwipe: true
      },
      // max-width 400
      {
          breakpoint: 480,
          preview: true,
          width: '100%',
          height: '350px',
          thumbnailSize: 'cover',
          imageSwipe: true,
          thumbnailsSwipe: true,
          previewSwipe: true,
      }
  ];

 
  }


  cargarVehiculo() {
      this._detalle.cargarVehiculoservice(this.id).subscribe(
          (res: any) => {
              this.carro = res.data;
              let imagenes = res.data.imagenes;
              for (const i in imagenes) {

                let objecImage = {
                    small: `${environment.link}imagenes_vehiculos/${imagenes[i].nombre_imagen}`,
                    medium: `${environment.link}imagenes_vehiculos/${imagenes[i].nombre_imagen}`,
                    big:    `${environment.link}imagenes_vehiculos/${imagenes[i].nombre_imagen}`,
                };
                this.galleryImages.push(objecImage);
               
              }

              console.log(this.galleryImages);
              
          
              
          }
      );
  }

  guardarLead(formu) {
    console.log(formu);
    
    if (formu.valid) {
      const data = {
        nombre: formu.value.nombre,
        email: formu.value.email,
        telefono: formu.value.telefono,
        terminos: formu.value.terminos,
        id_vehiculo: this.id
      };

      this._detalle.saveLeads(data).subscribe(
        (response: any) => {
          Swal.fire('Genial!', 'Un asesor pronto se contactará contigo!', 'success');
          formu.reset();
        }
      );
          // this._home.data = {
          //   nombre: '',
          //   email: '',
          //   telefono: '',
          //   comentario: '',
          //   terminos: true,
          //   interes: '',
          //   horarioatencion: '',
          //   financiamiento: '',
          // };
    }else{
      Swal.fire('Oops!', 'Los campos email, teléfono y nombre son obligatorios!', 'error');
    }
  }


}
