import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompraService {

  constructor( private _http:HttpClient ) { }


  listMarcas(){
    return this._http.get(environment.url + 'listmarcas');
  }
  listReferencias(marca:any){
    return this._http.get(environment.url + 'listreferencias/'+ marca);
  }
  listModelos(marca:any,referencia:any){
    return this._http.get(environment.url + 'listmodelos/'+ marca+ '/'+ referencia);
  }
  vehiculoEncotrado(marca:any,referencia:any, modelo:any){
    return this._http.get(environment.url + 'vehiculosencontrados/'+ marca+ '/'+ referencia + '/' + modelo);
  }

  saveCompra(data) {
    return this._http.post( environment.url + 'compravehiculo', data );
  }

  saveLeadSoporte(data) {
    return this._http.post( environment.url + 'leadsoporte', data );
  }

}
