import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DetalleComponent } from './detalle/detalle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxGalleryModule } from 'ngx-gallery';
import { HttpClientModule } from '@angular/common/http';
import { RutasPipe } from './pipes/rutas.pipe';
import { HomeService } from './services/home.service';
import { DetalleService } from './services/detalle.service';
import { NgMarqueeModule } from 'ng-marquee';
import { ContactoComponent } from './contacto/contacto.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { DetalleNoticiasComponent } from './detalle-noticias/detalle-noticias.component';
import { CompraComponent } from './compra/compra.component';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import { TerminosComponent } from './terminos/terminos.component';
import { SafePipe } from './safe.pipe';
import { NgxPaginationModule } from 'ngx-pagination';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DetalleComponent,
    RutasPipe,
    ContactoComponent,
    NosotrosComponent,
    NoticiasComponent,
    DetalleNoticiasComponent,
    CompraComponent,
    TerminosComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxGalleryModule,
    HttpClientModule,
    NgMarqueeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(options),
    CarouselModule.forRoot(),
    PaginationModule.forRoot()
  ],
  providers: [
    HomeService,
    DetalleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
