import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DetalleService {

  constructor(private _http:HttpClient) { }

  cargarVehiculoservice(id) {
    return this._http.get(environment.url+`listardetalleactivo/${id}`);
  }

  saveLeads(data) {
    return this._http.post( environment.url + 'crear_leads_usados', data );
  }
}
